import { A, cache, createAsync } from "@solidjs/router";
import { Accessor, createSignal, onMount, Show, Suspense } from "solid-js";
import { downloadProcurementOrder } from "~/server/apis/client_apis";
import {
  getProcurementPurchaseRouteData,
  ProcurementPurchaseRouteData,
} from "~/server/data/procurement_purchase_route_data";
import { EmptyState } from "~/widgets/empty_state";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";

const getProcurementPurchaseRouteData$C = cache(
  getProcurementPurchaseRouteData,
  "procurement_purchase"
);

export default function Purchases() {
  const routeData: Accessor<ProcurementPurchaseRouteData | undefined> =
    createAsync<ProcurementPurchaseRouteData>(() => {
      return getProcurementPurchaseRouteData$C();
    });

  const [loading, setLoading] = createSignal(false);

  const downloadOrder = async (orderId: string) => {
    setLoading(true);
    try {
      const response = await downloadProcurementOrder(orderId);
      if (response) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `hubble_procurement_order_${orderId}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download the order:");
      }
    } catch (error) {
      console.error("An error occurred while downloading the order:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Suspense
      fallback={
        <div class="flex-1 items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="flex-1 items-center justify-center">
        <div class="fixed z-10 w-full border border-baseTertiaryMedium bg-white px-5 py-3 text-h5 text-textDark">
          {" "}
          My purchases
        </div>
        <Spacer height={54} />
        <Show
          when={
            routeData()?.purchaseOrders?.data &&
            routeData()!.purchaseOrders?.data.length > 0
          }
          fallback={emptyState()}
        >
          {PurchaseTable()}
        </Show>
      </div>
    </Suspense>
  );

  function PurchaseTable() {
    return (
      <div class="h-[90vh] overflow-auto p-4 pb-10">
        <div class="shadow rounded-lg border border-baseTertiaryMedium bg-white">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="sticky -top-5 rounded-full  bg-gray-50">
              <tr>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  DATE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  AMOUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  COUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  NOTES
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  BRANDS
                </th>
                <th class="rounded-tr-lg px-3  py-2 text-left text-smallBold text-textDark">
                  CSV
                </th>
              </tr>
            </thead>
            <tbody class=" divide-y divide-gray-200  rounded-full bg-white ">
              {routeData()?.purchaseOrders.data.map((purchase) => (
                <tr
                  class={` text-medium last:rounded-b-lg  hover:cursor-pointer hover:bg-gray-200   ${purchase.status === "FAILED" ? "text-textNormal" : "text-textDark"}`}
                  onClick={() => {
                    if (
                      purchase.status === "FULFILLED" ||
                      purchase.status === "FAILED"
                    ) {
                      window.location.href = `/purchases/${purchase.id}`;
                    } else {
                      window.location.href = `/order/${purchase.id}`;
                    }
                  }}
                >
                  <td class="whitespace-nowrap border-r p-3 text-mediumBold last:rounded-bl-lg">
                    {new Date(purchase.transactedAt).toLocaleDateString(
                      "en-IN",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {purchase.totalAmount}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {purchase.voucherCount}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {purchase.notes && purchase.notes?.length > 0
                      ? purchase.notes
                      : "-"}
                  </td>

                  <td class=" whitespace-nowrap border-r p-3">
                    {purchase.brands.length > 4
                      ? purchase.brands.slice(0, 4).join(", ").toString() +
                        " +" +
                        (purchase.brands.length - 4) +
                        " more"
                      : purchase.brands.join(", ")}
                  </td>
                  <td class="whitespace-nowrap p-3 ">
                    <div
                      class="cursor-pointer p-1"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (purchase.status === "FULFILLED" && !loading()) {
                          downloadOrder(purchase.id);
                        }
                      }}
                    >
                      <PhosphorIcon
                        name={
                          purchase.status === "PROCESSING" ||
                          purchase.status === "PARTIALLY_FULFILLED"
                            ? "circle-notch"
                            : purchase.status === "FAILED"
                              ? "warning"
                              : "cloud-arrow-down"
                        }
                        fontSize={20}
                        class={`${purchase.status === "FAILED" ? "text-errorDark" : "text-baseSecondaryLight hover:text-black"}`}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function emptyState() {
    return (
      <EmptyState
        primaryText="Gifts cards you purchase will appear here"
        secondaryText="No gift cards purchased yet"
      />
    );
  }
}
